@import '~@styles/breakpoints';
.Label {
  cursor: pointer;
  &.DarkMode{
    font-size: 1.2rem;
    color: #dfdfdf;
    font-weight: 400;
  }
  @include media('<md'){
    display: flex;
    flex-direction: row;
  }
}

.Input {
  display: none;
}

.Checkbox {
  display: inline-flex;
  width: 2.1rem;
  height: 2.1rem;
  margin-right: 1rem;
  background: #f3f3f3;
  border: 2px solid #c1c1c1;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  .Input:checked + &,
  .Label:hover & {
    border-color: var(--primary-color);
  }
}
.DarkMode{
  .Checkbox{
    background: var(--dark-mode--checkBox-bg);
    border: 1px solid var(--dark-mode-checkBox-border);
  }
}
.FakeSpace{
  @include media('<md') {
    width: 50%;
  }
 
}
.CheckBoxContainer{
  @include media('<md') {
    padding: 0 1.5rem;
  }
 
}