@import '~@styles/breakpoints';

.Tile {
  display: flex;
  color: inherit;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.92);
  padding: 1.5rem;

  @include media('>=md') {
    flex-direction: column;
  }
  @include media('<md') {
    padding: 0;
  }

  &:hover,
  &:active {
    box-shadow: #c1c1c1 0 0 10px;
    color: inherit;

    .Title {
      color: var(--primary-color);
    }
  }
}

.ImageContainer {
  position: relative;
  padding-top: 100%;
  @include media('<md') {
    flex: 0 0 50%;
    padding-top: 50%;
  }
  @include media('>=md') {
    margin-bottom: 2.4rem;
  }
}

.Image {
  position: absolute;
  // top: 0;
  // left: 0;
  // object-fit: contain;
  // width: 100%;
  // height: 100%;
}

.Label {
  composes: TextSmall FontCondensed from '~@styles/typography.module.scss';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #fff;
  min-width: 8rem;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
}

.Content {
  display: flex;
  flex: 1;
  flex-direction: column;
  @include media('<md') {
    flex: 0 0 50%;
    padding: 0 1.5rem;
    font-size: 1.5rem;
  }
}

.Info {
  flex: 1;
}

.Title {
  composes: TextNormal FontBold from '~@styles/typography.module.scss';
  margin-bottom: 0;

  @include media('<md') {
    font-size: inherit;
  }
}

.QuickFeatures {
  display: block;
  padding: 1rem;
  opacity: 0.5;
  list-style: none;
}

.Compare {
  composes: TextSmall2 from '~@styles/typography.module.scss';
  display: block;
  margin-top: 3rem;
}

.Desc_container {
  padding: 10px
}

.Product_tile_desc {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.357583px;
  color: #070707;
}

.Price {
  font-size: 18px;
}
