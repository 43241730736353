@import 'breakpoints';

:root {
  --primary-color: #DC4405;
  --text-color: #000;
  --inverted-text-color: #fff;
  --bg-color: #fff;
  --inverted-bg-color: #000;
  --border-color: #f3f3f3;
  --nav-height: 5rem;
  --container-width: 136.6rem;
  --table-bg: transparent;
  --table-accent-bg: transparent;
  --table-border-color: #dee2e6;
  --table-text-color: #212529;
  --dark-mode-bg-color: #121212;
  --dark-mode-text-color: #fff;
  --dark-mode-secondary-text-color: #979797;
  --dark-mode--third-text-color:#DFDFDF;
  --dark-mode--checkBox-bg : #4D4D4D;
  --dark-mode-checkBox-border: #6F6F6F;
  --dark-mode-componnet-title: #E0E0E0;
  --submenu-backgroud: #F2F2F2;
  --nav-default-size: 1.4rem;
  --nav-default-weight: 600;
  --nav-sub-menu-title-size: 2.2rem;
  --nav-sub-menu-title-weight: 750;
  --nav-sub-menu-default-size: 1.8rem;
  --nav-sub-menu-default-weight: 600;
  --nav-sub-menu-subtitle-size: 1.4rem;
  --nav-sub-menu-subtitle-weight: 400;
  --post-title-default-size: 2.2rem;
  --large-post-title-default-size: 2.9rem;
  --modal-cancel-bg : #d8d8d8;
  --modal-table-border-color: #c4c4c4;
  --modal-table-gray-bg: rgba(196, 196, 196, 0.2);
  --dimmed: #888;

  @include media('>=md', '<lg') {
    --nav-height: 4rem;
  }

  @include media('>lg', '<xl') {
    --nav-height: 4.5rem;
  }
}
