.tabButtonWrapper {
  display: flex;
}

.tabButton {
  border: none;
  cursor: pointer;

  &.active {
    color: #fff;
    background-color: #e8421b;
  }
}
