@import '~@styles/breakpoints';

.List {
  display: block;
  list-style: none;
  padding: 5%;

  @include media('>=md') {
    padding: 4.5rem;
  }
  @include media('<md') {
    background: rgba(255, 255, 255, 0.75);   
    padding: 1rem 5%; 
    margin-bottom: 0;
  }
}

.Item {
  composes: TextSmall from '~@styles/typography.module.scss';
  display: inline-block;
  margin-right: 0.5rem;
  white-space: nowrap;
  color: #000;

  &:not(:last-child)::after {
    content: '>';
  }
  a{
    @include media('<md') {
      color: #000;
    }
  }
}

.Current {
  opacity: 0.7;
}

.Crumb {
  color: #000;
  display: inline-block;
  margin-right: 5px;
  white-space: nowrap;
}

.Selected {
  opacity: 0.7;
}
