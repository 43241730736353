@import '~@styles/breakpoints';

.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  img{
    transition: transform 0.4s;
  }


  &:hover,
  &:active {    
    color: inherit;
    img{
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
    }    

    .Title, .Text, .Body {
      color: var(--primary-color);
    }
  }

}

.Body {
  flex: 1;
  &:not(:first-child) {
    margin-top: 2.4rem;
  }

  @include media('<md') {
    padding: 5%;

    &:not(:first-child) {
      margin-top: 0;
    }
  }
}
.Text{
  overflow:hidden;
  min-height: 70px;
  p{
    &:not(:first-child) {
      display: none;
    }
  }
  
}
.Title {
  composes: H4 from '~@styles/typography.module.scss';
  font-size: 2.5rem !important;
  line-height: 1.1;
}
.SubTitle{
  composes: H5 from '~@styles/typography.module.scss';
}

.Media {
  //flex: 0;
  //min-height: 13rem;
  overflow: hidden; 

  > img {
    width: 100%;
  }

}
.More{
  margin-top: 2rem;
  font-size: 1.6rem;
  svg{
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-left: 1rem;
  }
}
