@import '~@styles/breakpoints';

.Step {
  width: 100%;
  text-align: center;
  user-select: none;
}

.Background {
  width: 100%;
  height: 4px;
  background-color: transparent;

  @include media('<md') {
    height: 2px;
  }
}

.Text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #8A8A8A;
  min-height: 60px;
  margin: 0;

  br {
    display: none;
  }

  @include media('<md') {
    font-size: 13px;
    line-height: 15px;

    br {
      display: block;
    }
  }
}

.StepBar {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;

  .Step {
    position: relative;
    list-style-type: none;
    float: left;
    cursor: pointer;

    &.Active {
      .Background {
        background-color: #DC4405;
      }
      .Text {
        color: #000;
      }
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @include media('<md') {
    min-height: 47.5px;
  }
}
