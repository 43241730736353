@import '~@styles/breakpoints';

#bomNav {
  .hidden {
    display: none !important;
  }

  #emailFormContainer {
    display: none;

    @include media('<md') {
      padding: 0 36px;
    }

    @include media('<sm') {
      padding: 0 28px;
    }
  }

  #configContent {
    #quotedispsbDisplay {
      display: grid;
      margin: 18px auto;
      padding: 0;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: 'selectedCol displayCol';

      @include media('<md') {
        display: flex;
        flex-direction: column;
      }

      #selectedSide {
        grid-area: selectedCol;

        #stickyCol {
          position: sticky;
          top: 100px;
        }

        #description {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 21px;
          letter-spacing: 0.3px;
          color: #000000;
          padding: 10px 30px;
        }

        #manifestActions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 30px;
          border-top: 2px solid #e0e0e0;
          border-bottom: 2px solid #e0e0e0;

          @include media('<sm') {
            justify-content: flex-end;
            flex-direction: column;
            padding: 10px;
          }

          .title {
            color: black;
            font-size: 1.8rem;

            #bmConfigTotal {
              font-size: 2.2rem;
              font-family: 'Helvetica Neue LT W05_85 Heavy', sans-serif;
            }
          }

          .actions {
            // display: none;
            display: flex;
            gap: 5px;

            .btn-custom {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              min-width: 50px;
              cursor: pointer;
              text-transform: uppercase;
              font-size: 1.3rem;
              color: #707070;
              padding: 4px 10px;
              border: none;

              @include media('<sm') {
                font-size: 1.2rem;
              }

              .icon {
                display: inline-block;
                background-position: center center;
                background-repeat: no-repeat;
                height: 15px;
                width: 15px;
                margin-right: 4px;
                cursor: pointer;
              }
              .envelope {
                background-image: url('/assets/icon/envelope.svg');
              }
              .share {
                background-image: url('/assets/icon/share.svg');
              }
              .print {
                background-image: url('/assets/icon/print.svg');
              }
              .copy {
                background-image: url('/assets/icon/copy.svg');
              }
            }
          }
        }
      }

      #sbDispHolder {
        grid-area: displayCol;
        background: var(--submenu-backgroud);

        @include media('<md') {
          padding: 30px 30px 0;
        }

        .title {
          background: #1a1a1a;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: #ffffff;
          padding: 14px 30px;
          margin-bottom: 10px;

          &.customize {
            display: none;
          }

          &.addOns {
            display: none;
          }

          &.summary {
            display: none;
          }
        }

        .description {
          display: none;
        }

        .subtitle {
          display: none;
        }

        .bomloaded,
        .primaryItem {
          display: none;
        }

        .ttlhld,
        .mpHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .edit-btn {
            display: none;
          }
        }

        .mp.padding {
          padding-top: 8px;
        }

        ul {
          list-style: none;
          padding: 0 25px;
          margin: 0;

          @include media('<sm') {
            padding-left: 0;
            padding-right: 0;
          }

          &.child {
            padding: 0;
            padding-left: 15px;
          }
        }

        > ul {
          padding: 0;
        }

        .notSelectable {
          color: var(--dimmed);

          .customCheckbox,
          .customRadio {
            display: none;
          }

          .info.icon {
            display: inline-block;
            background-image: url('/assets/icon/circle-info.svg');
            background-position: center center;
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            position: relative;
            top: 4px;
            left: 2px;
            margin-left: 6px;
            cursor: pointer;
            border-radius: 9px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
          }
        }

        > ul {
          > li {
            border-bottom: 1px solid #FFF;
          }

          li {
            margin: 0px 0px;

            .ttlhld {
              display: flex;
              justify-content: space-between;
              align-items: center;
              text-transform: uppercase;
              padding: 20px 25px 20px;

              @include media('<sm') {
                padding-left: 0;
                padding-right: 0;
              }
            }

            .mpHeader {
              display: inline-flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              width: 100%;
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
              font-size: 1em;
              color: var(--primary-color);
              text-transform: uppercase;
              margin-top: 0px;
              margin-bottom: 0px;
              padding: 20px 25px 20px;

              &.collapsed {
                color: #000;
                padding: 10px 25px 10px;
              }

              @include media('<sm') {
                padding-left: 0;
                padding-right: 0;
              }

              .accordionToggle {
                position: absolute;
                top: 0px;
                right: 0px;
                color: var(--primary-color);
                margin-left: 20px;
                width: 20px;
                height: 20px;

                svg {
                  display: block;
                  top: 0px;
                  position: absolute;

                  &.toggleOpen {
                    display: none;
                  }
                }
              }

              &.accordionClosed {
                .accordionToggle {
                  svg {
                    &.toggleClose {
                      display: none;
                    }
                    &.toggleOpen {
                      display: block;
                    }
                  }
                }
              }
            }

            ul {
              &.first {
                padding: 0;

                > li {
                  border-bottom: 1px solid #FFF;
                  margin: 0;
                }
              }

              li {
                margin-bottom: 20px;

                &:first-child {
                  .cmt {
                    margin-top: 0px !important;
                  }
                }

                .cc {
                  display: none;
                }

                .ih {
                  display: inline;
                }

                .di {
                  display: flex;
                  color: rgb(26, 26, 26);

                  .prd-dsec {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 100%;

                    .ca {
                      display: flex;
                      align-items: center;
                    }
                  }

                  span {
                    &.ca {
                      &.ng {
                        display: inline-block;

                        .gg {
                          display: none;
                        }
                      }
                    }
                  }

                  .cmt {
                    span {
                      font-weight: bold;
                      color: var(--primary-color);
                    }
                  }

                  .dh {
                    display: inline-block;

                    &.cmt {
                      display: none;
                      margin-top: 20px;
                      width: 100%;
                      font-size: 1.1em;
                      padding-bottom: 5px;
                      border-bottom: 2px dotted var(--primary-color);
                    }
                  }

                  .pp {
                    color: var(--text-color);
                    margin-top: 15px;

                    &.ng {
                      right: 0px;
                    }
                  }
                  span.pp.ng.bdr {
                    font-weight: bold;
                    position: absolute;
                    right: 0px;

                    @include media('<md') {
                      position: relative;
                      text-align: right;
                      margin-left: auto;
                    }
                  }
                  div.pp.ng.bdr {
                    flex: 1 0 100%;
                  }
                }

                .mp {
                  .lp {
                    font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
                    white-space: nowrap;
                  }
                }
              }
            }

            .collapse-btn {
              display: inline-block;
              width: 26px;
              min-width: 26px;
              height: 26px;
              font-size: 22px;
              line-height: 23px;
              color: #1a1a1a;
              cursor: pointer;
              text-align: center;
              user-select: none;

              .chevron::before {
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: inline-block;
                height: 12px;
                position: relative;
                width: 12px;
              }

              .minus {
                display: block;
                color: var(--primary-color);

                &::before {
                  top: 3px;
                  transform: rotate(-45deg);
                }
              }
              .plus {
                display: none;

                &::before {
                  top: -3px;
                  transform: rotate(135deg);
                }
              }

              &.collapsed {
                .minus {
                  display: none;
                }
                .plus {
                  display: block;
                }
              }
            }
          }

          #ordergroupsdisplay {
            .ogdTitle {
              .ttlhld {
                color: var(--primary-color);
                font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;

                &.collapsed {
                  color: #000;
                  padding: 10px 25px 10px;
                }
              }
            }
          }

          #ogdlist {
            li {
              margin-bottom: 20px;
            }
          }

          &#addOnsList {
            > li {
              .cd,
              .md {
                display: block;
              }
              .ogdi {
                margin-top: 10px;
              }
              .btn-custom {
                min-width: 73px;

                &.fr {
                  min-width: 83px;
                }
              }
              .ogdAction {
                border: none;
                padding: 0;

                .edit-btn {
                  margin: -10px 25px 15px;
                }
              }
            }
          }
        }

        .sectionTitle {
          display: none;
        }

        .ogdisp-item {
          position: relative;
          display: flex;
          justify-content: space-between;
          border: 2px solid #c0c0c0;
          padding: 15px;

          @include media('<sm') {
            flex-direction: column;
          }

          &.primary {
            display: none;
          }

          &.selectedRow {
            background: white;
            border: 2px solid var(--primary-color);
            box-shadow: 0 0 3px #dc4418;

            .ogdisp-action {
              .btn-primary {
                display: none;
              }
              .btn-remove {
                display: inline-block;
              }
            }
          }

          &.addon-item {
            padding: 0;

            .ogdisp-info {
              display: flex;
            }

            .ogdisp-thumb {
              max-width: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-right: 2px solid #c0c0c0;
              cursor: pointer;
            }

            .ogdi-data {
              padding: 12px 8px 12px 12px;

              .ogdi:first-child {
                margin: 0 !important;
              }

              .chd-code {
                display: block;
                font-size: 80%;
                margin-top: 4px;
              }
            }

            .ogdisp-action {
              padding-right: 15px;

              @include media('<sm') {
                padding: 0 0 15px 15px;
              }
            }
          }

          .ogdisp-action {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 77px;

            @include media('<sm') {
              justify-content: left;
              padding-top: 10px;
            }

            &.fr {
              min-width: 119px;
            }

            .btn-primary {
              display: inline-block;
            }
            .btn-remove {
              display: none;
            }
          }

          ul {
            margin: 0;
            margin-bottom: 10px;
            padding: 0;

            li {
              margin-top: 8px !important;
              margin-bottom: 0 !important;
              color: rgb(26, 26, 26);
            }
          }
        }

        .ogdi {
          width: 100%;
          display: flex;

          .ck {
            position: absolute;
            left: -8px;
            top: -10px;
            width: auto;
          }

          .hld {
            display: inline-block;
            flex: 1;

            .cd,
            .md {
              display: none;
            }

            .dc {
              display: block;
              color: rgb(26, 26, 26);
            }

            .lp {
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
            }
          }
        }

        .sbdisp-item {
          position: relative;
          display: flex;
          justify-content: space-between;
          border: 2px solid #c0c0c0;
          padding-right: 15px;

          @include media('<sm') {
            flex-direction: column;
            padding-right: 0;
            padding-bottom: 15px;
          }

          &.selectedRow {
            background: white;
            border: 2px solid var(--primary-color);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

            .sbdisp-action {
              .btn-primary {
                display: none;
              }
              .btn-remove {
                display: inline-block;
              }
            }
          }

          &.subsection {
            border: none;
            margin-bottom: 10px;

            .purecmt {
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
              padding-left: 5px;
            }
          }

          .sbdisp-info {
            .prd-img {
              @include media('<sm') {
                padding-bottom: 10px;
              }
            }

            .sbdisp-info-text {
              display: none;
            }

            .prd-dsec {
              padding: 15px;
            }

            .chd-code {
              display: block;
              font-size: 80%;
              margin-top: 4px;
            }

            &.no-prod-img {
              padding: 15px;

              @include media('<sm') {
                padding-bottom: 10px;
              }

              .prd-dsec {
                padding: 0;
              }
            }
          }

          .purecmt {
            padding-left: 15px;
            padding-right: 15px;
          }

          .sbdisp-action {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 77px;

            @include media('<sm') {
              justify-content: left;
              padding-left: 15px;
            }

            &.fr {
              min-width: 119px;
            }

            .btn-primary {
              display: inline-block;
            }
            .btn-remove {
              display: none;
            }
          }

          .ca {
            .ih.cb,
            .ihh.cb {
              position: absolute;
              left: -8px;
              top: -10px;
              width: auto;
            }
            .num {
              display: none;
            }
          }
        }

        input[type='checkbox'] {
          &.replaceWithCustom {
            display: none;
          }
        }
        input[type='radio'] {
          &.replaceWithCustom {
            display: none;
          }
        }

        .customRadio {
          display: none;
          background-image: url('/assets/icon/circle-outline.svg');
          background-position: center center;
          background-repeat: no-repeat;
          width: 22px;
          height: 22px;
          border-radius: 11px;
          position: relative;
          top: 3px;
          margin-right: 10px;
          cursor: pointer;

          &.checked {
            display: inline-block;
            background-image: url('/assets/icon/check-solid-orange.svg');
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
          }

          &.disabled {
            cursor: not-allowed;
          }
        }

        .customCheckbox {
          display: none;
          background-image: url('/assets/icon/circle-outline.svg');
          background-position: center center;
          background-repeat: no-repeat;
          width: 22px;
          height: 22px;
          border-radius: 11px;
          position: relative;
          margin-right: 10px;
          top: 3px;
          cursor: pointer;

          &.checked {
            display: inline-block;
            background-image: url('/assets/icon/check-solid-orange.svg');
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
          }

          &.disabled {
            cursor: not-allowed;
          }
        }

        .child-thumb {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 93px;
          height: 100%;
          border-right: 2px solid #c0c0c0;
          cursor: pointer;

          img {
            width: 100%;
          }

          &.hide {
            img {
              display: none;
            }
          }
        }

        #depDialog {
          display: none;
        }

        .btn-custom {
          font-family: 'nimbus-sans', sans-serif;
  
  font-weight: 600;          cursor: pointer;
          text-transform: uppercase;
          font-size: 1.4rem;
          padding: 6px 8px;
          border: none;

          &:hover {
            opacity: 0.9;
          }

          &.btn-primary {
            background: var(--primary-color);
            color: white;
          }
          &.btn-remove {
            background: var(--dark-mode-bg-color);
            color: white;
          }
        }

        .details {
          display: none;
        }

        .total-price {
          display: none;
        }

        .quoteButton {
          padding-bottom: 20px;
        }

        #customizeList {
          > .customize {
            .first {
              > li {
                display: none;
              }
              > li.configuration {
                display: block;
              }
            }
          }

          > .configuration {
            display: block;
            padding-top: 20px;
          }
        }

        #addOnsList {
          display: none;
        }

        &.customize {
          .title {
            display: none;

            &.customize {
              display: block;
            }
          }

          .sectionTitle {
            display: none;
          }

          #customizeList {
            > .customize {
              .first {
                > li {
                  display: block;
                }
                > li.configuration {
                  display: none;
                }
              }
            }

            > .configuration {
              display: none;
            }
          }

          #addOnsList {
            display: none;
          }
        }

        &.addOns {
          .title {
            display: none;

            &.addOns {
              display: block;
            }
          }

          .sectionTitle {
            display: none;
          }

          .description {
            display: block;
            font-weight: bold;
            font-size: 1.4rem;
            padding: 10px 30px 15px;

            @include media('<sm') {
              padding-left: 10px;
              padding-right: 10px;
            }

            span {
              display: block;
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
              font-size: 3.6rem;
              line-height: 1;
              text-transform: capitalize;
            }
          }

          #customizeList {
            display: none;
          }

          #addOnsList {
            display: block;
          }
        }

        &.summary {
          .title {
            display: none;

            &.summary {
              display: block;
            }
          }

          .subtitle {
            display: flex;
            gap: 20px;
            align-items: center;
            font-weight: bold;
            font-size: 1.8rem;
            text-transform: uppercase;
            padding: 15px 30px 30px;

            @include media('<sm') {
              padding-left: 10px;
              padding-right: 10px;
            }

            .subtitleImg {
              max-width: 150px;
              padding: 8px;
              background: #ffffff;
              border: 1px solid #000;
              box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
            }

            .subtitleText {
              flex: 1;
              color: rgb(26, 26, 26);
              font-size: 1.6rem;
              border-bottom: 2px solid var(--primary-color);
              padding-bottom: 15px;

              span {
                display: block;
                font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
                font-size: 2.2rem;
                color: #000;
                letter-spacing: -1px;
                line-height: 1;
                text-transform: capitalize;
                margin-top: 8px;
              }
            }
          }

          .ttlhld,
          .mpHeader {
            border-top: 2px solid white;
            border-bottom: none;
            margin-bottom: 0;
            cursor: pointer;
            color: var(--primary-color) !important;
            display: flex !important;

            .edit-btn {
              display: inline-block;
                font-family: 'nimbus-sans', sans-serif;
              font-weight: normal;
              color: var(--primary-color);
              cursor: pointer;
              text-align: right;
              text-transform: capitalize;
              min-width: 73px;

              &:hover {
                opacity: 0.85;
              }
            }
            .collapse-btn {
              display: none;
            }
          }

          .ogdisp-item,
          .sbdisp-item {
            display: none;
            border: none;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

            &.primary {
              display: none;
            }

            &.selectedRow {
              display: flex;
              box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
            }

            .ogdi.bdr,
            .pp.ng.bdr {
              text-align: right;
            }

            .ogdisp-info,
            .sbdisp-info {
              flex: 1;
              padding-right: 0;

              .ogdi-data {
                flex: 1;
                padding-right: 15px;
              }
            }

            .sbdisp-info {
              @include media('<sm') {
                padding-right: 15px;
              }
            }

            .ogdisp-action,
            .sbdisp-action {
              display: none;
            }
          }

          .details {
            display: block;
            border-top: 2px solid white;
            padding: 20px 25px 30px;

            @include media('<sm') {
              padding-left: 0;
              padding-right: 0;
            }

            .details-title {
              text-transform: uppercase;
              margin-bottom: 10px;
              color: var(--primary-color);
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
            }

            .highlighed {
              color: var(--primary-color);
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
            }

            ul {
              background: #ffffff;
              padding: 16px 25px;
              margin: 0;
              box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);

              li {
                margin: 4px 0;

                div {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .dc {
                    color: rgb(26, 26, 26);
                  }

                  .pp {
                    font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
                    line-height: 1;

                    span {
                      white-space: nowrap;
                    }
                  }
                }

                &.mfi.hidden {
                  display: none;
                }
              }
            }
          }

          .total-price {
            display: block;
            padding: 0 25px;
            margin-bottom: 30px;

            @include media('<sm') {
              padding-left: 0;
              padding-right: 0;
            }

            .legal {
              display: block;
              margin-top: 1em;
            }
            .info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: #ffffff;
              padding: 20px 15px;
              box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);

              .dc {
                color: rgb(26, 26, 26);
              }

              .pp {
                font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
                font-size: 2.6rem;
                line-height: 1;

                &:before {
                  content: '';
                  font-size: 2.4rem;
                }
              }

            }
          }

          .quoteButton {
            padding: 0 25px 30px;

            @include media('<sm') {
              padding-left: 0;
              padding-right: 0;
            }
          }

          #customizeList {
            > .customize {
              .first {
                > li {
                  display: none;
                  border: none;

                  &:has(.selectedRow) {
                    display: block;
                  }

                  .mpHeader {
                    border: none;
                    padding-top: 0;
                  }

                  .edit-btn {
                    display: none !important;
                  }
                }
                > li.configuration {
                  .mpHeader {
                    padding-top: 15px;
                  }
                }
              }

              .sectionTitle {
                border-top: 2px solid white;
                border-bottom: none;
                margin-bottom: 0;
                cursor: pointer;
                color: var(--primary-color);
                font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
                padding: 20px 25px 20px;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;

                .edit-btn {
                  font-family: 'nimbus-sans', sans-serif;
                  text-transform: capitalize;
                }
              }
            }

            > .configuration {
              display: block;

              &.hidden {
                display: block !important;
              }
            }

            .prmItem.empty {
              display: none;
            }
          }

          #addOnsList {
            display: block;

            .prmItem {
              border: none;

              &.empty {
                display: none;
              }

              .ttlhld {
                border: none;
                padding-top: 0;
              }
            }

            .sectionTitle {
              border-top: 2px solid white;
              border-bottom: none;
              margin-bottom: 0;
              cursor: pointer;
              color: var(--primary-color);
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
              padding: 20px 25px 20px;
              font-size: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              text-transform: uppercase;

              .edit-btn {
                font-family: 'nimbus-sans', sans-serif;
                text-transform: capitalize;
              }
            }

            .ogdAction {
              display: none !important;
            }
          }
        }
      }

      #continueButton {
        @include media('<md') {
          padding: 0px 29px;
        }
      }
    }

    .summaryFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 30px;

      @include media('<lg') {
        justify-content: flex-end;
        flex-direction: column;
      }

      .productSummary {
        display: inline-block;
        margin-right: 30px;

        @include media('<md') {
          margin-right: 15px;
        }

        @include media('<sm') {
          display: block;
          margin-right: 0;
          margin-bottom: 14px;
        }

        .btn-custom {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: var(--primary-color);
          height: 48px;
          color: #707070;
          padding: 6px 8px 6px 4px;
          border: none;
        }

        #mfTotal {
          display: flex;
          align-items: center;
          color: var(--primary-color);
          padding-right: 24px;
          border-right: 2px solid #c0c0c0;
          cursor: pointer;

          @include media('<lg') {
            padding-right: 0;
            justify-content: center;
            border-right: none;
          }

          .dc {
            font-size: 1.2em;
            font-weight: bold;
            margin-right: 15px;

            @include media('<md') {
              font-size: 1em;
              margin-right: 12px;
            }
          }

          .pp {
            color: black;

            &::before {
              content: '';
              font-size: 3.2rem;
              font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
            }
          }

          .legal {
            margin-left: 10px;

            .info.icon {
              display: inline-block;
              background-image: url('/assets/icon/circle-info.svg');
              background-position: center center;
              background-repeat: no-repeat;
              height: 18px;
              width: 18px;
              position: relative;
              cursor: pointer;
              border-radius: 9px;
              box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
            }
          }

          .net {
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: #737b7d;
            margin-left: 6px;
          }

          #bmConfigTotal {
            font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
            font-weight: bolder;
            font-size: 3.4rem;
            letter-spacing: -0.5px;
          }
        }

        #calculatorSummary {
          display: flex;
          gap: 16px;

          > div {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
          }
          p {
            font-size: 14px;
            margin-bottom: 0;
            text-align: center;
          }
          h3 {
            letter-spacing: -0.5px;
            margin-bottom: 0;
            text-align: center;

            small {
              font-family: "Helvetica Neue LT W05_55 Roman";
            }
          }
        }
      }

      .cta {
        display: inline-flex;
        gap: 10px;

        .btn-custom {
          min-width: 170px;
          font-family: 'nimbus-sans', sans-serif;
  
  font-weight: 600;          cursor: pointer;
          text-transform: uppercase;
          font-size: 1.8rem;
          color: #707070;
          border: 1px solid #c0c0c0;
          padding: 14px 20px;

          @include media('<sm') {
            min-width: 150px;
            font-size: 1.6rem;
            padding: 12px 14px;
          }

          .icon {
            display: inline-block;
            background-image: url('/assets/icon/redo.svg');
            background-position: center center;
            background-repeat: no-repeat;
            height: 15px;
            width: 15px;
            margin-right: 4px;
            cursor: pointer;
          }
        }
      }
    }

    .payment-calculator {
      padding: 16px 8px;
      display: flex;
      width: 100%;

      @media (max-width: 47.99em) {
        flex-direction: column;
      }

      .calculator-info {
        width: 35%;
        padding: 0 15px;
        border-right: 1px solid #eaeaea;

        span {
          font-size: 13px;
          margin-bottom: 4px;
        }

        h2 {
          font-size: 24px;
          margin-bottom: 12px;
        }

        @media (max-width: 47.99em) {
          width: 100%;
          border-right: none;
        }
      }

      .calculator-content {
        width: 65%;
        padding: 8px 15px;

        @media (max-width: 47.99em) {
          width: 100%;
          margin-top: 15px;
        }
      }

      p {
        margin-bottom: 4px;
      }
      input:not([type="checkbox"]), select {
        width: 80%;
      }

      .flex {
        display: flex;
      }

      .items-center {
        align-items: center;
      }

      .row {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -12px;
        margin-left: -12px;
      }

      .col {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 12px;
        padding-left: 12px;
        margin-bottom: 20px;

        @media (min-width: 36em) {
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
        }
      }

      .btn {
        display: inline-block;
        color: var(--text-color);
        background: var(--bg-color);
        border: 0;
        padding: 0.375em 0.55em;
        cursor: pointer;
        text-align: center;
        font-size: 1.4rem;
        border: 2px solid #DC4405;

        &:hover,
        &:active,
        &.active {
          color: var(--inverted-text-color);
          background: var(--primary-color);
        }

        &.small {
          padding: 0.25em 0.375em;
        }
      }
    }
  }

  #buildandquoteSummary {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    background-color: white;
    overflow: hidden;

    .content {
      padding: 2rem 1rem;
      width: 85vw;

      @media (min-width: 48em) {
        padding: 3rem;
      }

      @media (min-width: 64.01em) {
        padding: 4rem;
      }
    }

    .content .branding {
      display: flex;
      align-items: center;
      background: #f2f2f2;
    }
    .content .branding .image {
      width: 270px;

      @media (max-width: 47.99em) {
        width: 160px;
      }
    }
    .content .branding .image img {
      width: 100%;
    }
    .content .branding .text {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #000000;
      padding: 0 40px;

      @media (max-width: 47.99em) {
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0px;
        padding: 0 20px;
      }
    }
    .content .display .title {
      font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
      font-weight: 700;
      font-size: 36px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .content .display .product {
      display: grid;
      grid-gap: 3rem;
      padding: 0;
      /* grid-template-columns: 1fr 2fr; */
      grid-template-rows: auto;
      grid-template-areas: 'manifest image';

      @media (max-width: 47.99em) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .content .display .manifest {
      grid-area: manifest;
    }

    .content .display .manifest .list {
      list-style: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: #000000;
      margin: 0;
      padding: 0;
    }
    .content .display .manifest .list li {
      position: relative;
      margin-bottom: 20px;

      &.highlighed {
        color: var(--primary-color);
        font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
      }
    }
    .content .display .manifest .list li p {
      font-weight: 300;
      margin-top: 6px;
      margin-bottom: 0;

      span.cd {
        display: block;
      }

      span.chd-code {
        display: block;
        font-size: 80%;
      }
    }

    .content .display .manifest .total {
      font-weight: 700;
      font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
      margin-bottom: 10px;

      .text, .price {
        font-size: 18px;
      }
    }

    /* .content .display .manifest .total .price::before {
      content: '$';
    } */
    .content .display .product .image {
      grid-area: image;
      text-align: center;
      padding: 0 30px;

      @media (max-width: 47.99em) {
        padding: 10px;
      }
    }
    .content .display .product .image img {
      max-width: 100%;
    }
    .close-button {
      font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
      display: inline-block;
      color: white;
      background: #dc4405;
      border: 0;
      padding: 1em;
      cursor: pointer;
      text-align: center;
      text-transform: uppercase;
      margin-top: 1em;
    }
  }
}
