@import '~@styles/breakpoints';

.Wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/icon/360-arrow-2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 0;
  }
  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  &.DarkMode {
    &:before {
      background-image: url('/assets/icon/dark-mode-360-arrow.svg');
      background-size: contain;
    }
  }
}

.IsSpin {
  min-height: 45rem;

  @include media('<lg') {
    min-height: 25rem;
  }
  @include media('<md') {
    min-height: 35rem;
  }
}

.NoSpinBg {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  &.DarkMode {
    &:before {
      background-image: url('/assets/icon/dark-mode-360-arrow.svg');
      background-size: contain;
    }
  }
}

.Button {
  --button-text-color: var(--text-color);
  --button-bg:  var(--bg-color);
  composes: FontCondensed from '~@styles/typography.module.scss';
  display: inline-block;
  color: var(--text-color);
  background: var(--bg-color);
  border: 0;
  padding: 0.5em 1em;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  border: 2px solid #DC4405;

  &:hover,
  &:active,
  &.active {
    color: var(--inverted-text-color);
    --button-text-color: var(--inverted-text-color);
    background: var(--primary-color);
    --button-bg:  var(--primary-color);
  }
}

.SVG {
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  align-self: center;
  margin-left: 30px;

  @include media('<md') {
    margin-left: 0.3rem;
  }
}

.IconBox {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
  z-index: 1;

  @include media('<md') {
    margin-bottom: 0rem;
    margin-top: 3rem;
  }
}

.DarkMode {
  .Button {
    background: none;
    color: var(--inverted-text-color);

    &:hover,
    &:active,
    &.active {
      color: var(--inverted-text-color);
      --button-text-color: var(--inverted-text-color);
      background: var(--primary-color);
      --button-bg:  var(--primary-color);
    }
  }
}

.Active {
  color: var(--inverted-text-color);
  --button-text-color: var(--inverted-text-color);
  background: var(--primary-color);
  --button-bg: var(--primary-color);
}
