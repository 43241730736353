@use 'sass:math';
@import '~@styles/breakpoints';

.FeaturedItem {
  position: relative;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
  @include media('<=md') {
    padding-top: percentage(math.div(510, 750));
  }
}
.Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 5%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0);
  }

  @include media('>=md') {
    padding: Max(percentage(math.div(36, 850)), 3.6rem)
      Max(percentage(math.div(48, 510)), 4.8rem);
  }
}

.Title {
  composes: H3 TextInverted from '~@styles/typography.module.scss';
  margin-bottom: 1.2rem;
  @include media('<=md') {
    font-size: 2.2rem;
    line-height: 1.2em;
  }
}

.Text {
  composes: TextInverted from '~@styles/typography.module.scss';
}

.ScaleEffect {
  //transition: transform 0.4s;
  overflow: hidden;
  img{
    transition: transform 0.4s, -webkit-transform 0.4s, -moz-transform 0.4s;
  }  
  &:hover {
    img{
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
    }
   
  }
}
