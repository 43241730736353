@import '~@styles/breakpoints';

.Background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;

  img,
  video,
  iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .DarkerShadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 55em 0px 15em 0 rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;

    @include media('>=xl') {
      box-shadow: inset 55em 0px 15em 0 rgba(0, 0, 0, 0.8);
    }
    @include media('<md') {
      //box-shadow: inset 0px -30rem 100px 5px rgba(31, 31, 31, 1);
      box-shadow: inset 0 18rem 100px 5px #000;
    }
  }
}

.DefaultShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 55em 0px 20em 0 rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;

  @include media('<lg') {
    box-shadow: inset 17em 0px 9em 0 rgba(0, 0, 0, 0.8);
  }
  @include media('<md') {
    box-shadow: inset 9em 2em 9em 0 rgba(0, 0, 0, 0.8);
  }
}

.BottomShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -19em 3em -8em rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;

  @include media('<md') {
    box-shadow: inset 0px -16em 4em -8em rgba(0, 0, 0, 0.85);
  }
}


/*For change video width to 100%*/
.Wrapper {
  position: relative;

  @include media('>=md') {
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  @include media('<md') {
    padding-top: 80%;
  }
  // @media (min-aspect-ratio: 16/9) {
  //   width:100%;
  //     height: auto;
  // }
  // @media (max-aspect-ratio: 16/9){
  //   width:auto;
  //   height: 100%;
  // }
}

.Player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.7);
  display: block !important;
}


.Parallax {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;

  @include media('<=sm'){
    background-size: cover;
  }
  @include media('<md') {
    background-position: center top;
  }
  //for very large screens background size becomes to cover
  @include media('>=md','<=lg') {
    /*background-size: cover;*/
  }
  //for very large screens background size becomes to cover
  @media screen and (min-width: 1900px) {
    /*background-size: cover;*/
  }
}

.VimeoWrapper {
  background: #eee;
  height: 600px;
  overflow: hidden;
  padding: 0;
  position: relative;
  iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
  }
}

.HeroParallex {
  div {
    //inset: -100px 0px !important;
    inset: -24px 0px !important;

    @include media('<md'){
      inset: -31px 0px !important;
    }
  }
}