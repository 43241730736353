@import '~@styles/breakpoints';

.Tile {
  display: flex;
  color: inherit;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.92);
  padding: 0;
  position: relative;
  overflow: hidden;

  &.IsStudio {
    overflow: visible;
  }

  .ImgBox {
    position: relative;
    overflow: hidden;

    &.StudioStyle {
      overflow: visible;
    }
  }

  .Image, .TopImage {
    transition: transform .4s;
  }

  @include media('>=md') {
    flex-direction: column;
  }
  @include media('<=md') {
    flex-direction: column;
    border-bottom: 0.2rem solid var(--border-color);
    padding: 2rem 0 0;
    margin-bottom: 2rem;
  }
  &:last-child {
    @include media('<=md') {
      border-bottom: 0;
    }
  }

  &:hover,
  &:active {
    color: inherit;

    .Image {
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
    }

    .TopImage {
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
    }

    .Title {
      color: var(--primary-color);
    }
  }
}

.ImageContainer {
  // position: relative;
  // padding-top: 100%;

  @include media('<md') {
    flex: 0 0 50%;
    //padding-top: 50%;
  }

  @include media('>=md') {
    margin-bottom: 2.4rem;
  }
}

.Image {
  // position: absolute;
  // top: 0;
  // left: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.LabelContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;

  @include media('<md') {
    left: 0;
    right: auto;
  }
}

.Label {
  composes: TextSmall FontCondensed from '~@styles/typography.module.scss';
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #fff;
  min-width: 8rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.1rem;
}

.Content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1.5rem 1.5rem;

  @include media('<md') {
    padding: 1.5rem 0.5rem 1.5rem 1.5rem;
    font-size: 1.5rem;
  }
}

.Info {
  flex: 1;
}

.Title {
  composes: FontBold from '~@styles/typography.module.scss';
  margin-bottom: 0;

  @include media('<md') {
    font-size: inherit;
  }
}

.SubTitle {
  composes: TextNormal from '~@styles/typography.module.scss';
  margin-bottom: 0;

  @include media('<md') {
    font-size: inherit;
  }
}

.SmallDescription {
  composes: TextSmall2 from '~@styles/typography.module.scss';
  margin-top: 8px;
  margin-bottom: 0;

  p {
    margin-bottom: 5px;
  }

  @include media('<md') {
    font-size: inherit;
  }
}

.QuickFeatures {
  display: block;
  padding: 1rem;
  opacity: 0.5;
  list-style: none;

  li {
    font-size: 1.3rem;
  }

  @include media('<md') {
    padding: 1rem 0;
  }
}

.catQuickFeatures {
  display: block;
  padding:5px 5px 0;
  padding-top: 5px;
  margin-top: 5px;
  border-top:1px solid #ccc;

  opacity: 1;
  list-style: none;
  font-size: 1.3rem;
  b {
    font-weight: 700;
  }
  li {
    &.bolder {
      font-weight: bolder;
      // font-size: 1.8rem;
    }
    &.price {
      font-weight: bolder;
      font-size: 1.6rem;
    }
  }
}

.Compare {
  composes: TextSmall2 from '~@styles/typography.module.scss';
  display: block;
  padding: 1.5rem;
}

.Title {
  color: var(--text-color);
}

.DarkMode {
  &.Tile {
    &:hover,
    &:active {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
      background: #252525;
      border: 2px solid #979797;
      color: var(--dark-mode--third-text-color) !important;
    }

    .QuickFeature {
      color:var(--dark-mode-secondary-text-color);
    }

    &.AccessoriesTile {
      @include media('<md') {
        border-bottom: 0;
      }

      .Title {
        font-size: 1.6rem;
        font-weight: 750;
      }
      .SubTitle {
        font-size: 1.6rem;
        font-weight: 400;
        color: #979797;
      }
    }
    .Title,
    .SubTitle,
    .Price {
      color: var(--dark-mode--third-text-color);
      font-size: 1.8rem;
      font-weight: 750;
    }
    .SubTitle {
      font-size: 1rem;
      font-weight: normal;
    }

    .QuickFeatures,
    .catQuickFeatures {
      color: var(--dark-mode-secondary-text-color);
      font-size: 1.4rem;
    }
  }
}
