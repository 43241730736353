@import '~@styles/breakpoints';

.Navbar {
  --nav-bg: #fff;
  --nav-height: 5rem;
  height: var(--nav-height);
  background: var(--nav-bg);

  @include media('<md') {
    --nav-height: 5rem;
    background: var(--nav-bg);
  }

  &.RichNavbar {
    --nav-height: 10rem;
    background: linear-gradient(180deg, #efefef 0%, #efefef 40%, #ffffff 40%, #ffffff 100%);

    @include media('<md') {
      --nav-height: 5rem;
      background: var(--nav-bg);
    }
  }
}

.Container {
  display: flex;
  height: 100%;

  &.RichContainer {
    @include media('>=md') {
      flex-direction: column;
    }
  }
}

.Branding {
  display: flex;
  align-items: center;
  flex: 0 auto;
  height: 100%;
  font-size: 1.6rem;

  .Logo {
    max-width: 220px;
    fill: #000;
  }

  .LogoStatement {
    fill: var(--text-color);

    path {
      &:first-child{
        fill: var(--text-color) !important;
      }
    }
  }
}

.Logo {
  width: auto;
  height: 100%;
}

.LogoStatement {
  width: 15.6rem;
  height: 100%;
  padding: 0.65rem 1rem;
}

.MobileNav {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: var(--bg-color);
  height: calc(100vh - var(--nav-height));
  overflow: hidden;
  box-shadow: 0px 1rem 1.3rem #333;
}

.RichMainNav {
  composes: TextSmall2 from '~@styles/typography.module.scss';
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  list-style: none;
  position: relative;

  @include media('<md') {
    max-height: 100vh;
    overflow-y: auto;

    &:last-child {
      padding-bottom: 10rem;
    }
  }
  @include media('>=md') {
    display: flex;
    align-items: stretch;
    flex: auto;
    width: 100%;
    gap: 20px;

    &:first-child {
      background: #efefef;
      height: 4rem;
    }
    &:last-child {
      padding-right: 50px;
      height: 6rem;
    }
  }
  > li {
    @include media('>=md') {
      &:before {
        content: "";
      }
      &:first-child{
        flex: 2;
      }
    }

  }
}

.MainNav {
  composes: TextSmall2 from '~@styles/typography.module.scss';
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  list-style: none;

  @include media('<md') {
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 10rem;
  }
  @include media('>=md') {
    display: flex;
    align-items: stretch;
    flex: auto;
  }
  > li {
    @include media('>=md') {
      &:before {
        content: "";
      }
      &:first-child {
        flex: 2;
      }
    }
  }
}

.MenuItem {
  color:var(--text-color);

  &::before {
    display: none;
  }

  @include media('>=md') {
    margin: 0 0.4rem;
    display: flex;
    align-items: stretch;
    flex: 0 0 auto;
    // flex: 0.4;
  }
  @include media('>=lg') {
    margin: 0 0.5vw;
  }
  @include media('>=xl') {
    margin: 0 1.2rem;
  }
  &:last-child {
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
}

.Icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.MobileToggle {
  border: 0;
  background: none;
  padding: 0;
  padding-right: 1.6rem;
  color: #000;
  cursor: pointer;

  &:active,
  &:hover {
    outline: none;
  }
}

.Link {
  display: flex;
  align-items: center;
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  outline: none;
  white-space: nowrap;
  font-size: var(--nav-default-size);
  font-weight: var(--nav-default-weight);
  letter-spacing: -0.1px;
  transition: 0.1s;

  @include media('<md') {
    font-size: 1.6rem;
    padding: 1.6rem;
    color: var(--text-color);
  }

  @include media('>=md', '<lg') {
    font-size: 1.4vw;
  }

  &:hover,
  &:active {
    color: var(--primary-color);
    cursor: pointer;
    border-bottom: 3px solid var(--primary-color);
  }
}

.MobileTopNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
