@import 'breakpoints';
@import 'variables';
//@import 'table';
@import 'bomNav';

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  word-break: normal;
}

body {
  position: relative;
  font-family: 'nimbus-sans', sans-serif;
  font-size: 1.6rem;
}

article {
  font-size: 1.6rem;
}

* {
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
  margin-bottom: 1.8rem;

  @include media('>=md') {
    margin-bottom: 2.4rem;
  }
}

strong {
  font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
blockquote,
pre,
address {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--primary-color);
  }
}

a,
button {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: color background border opacity;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fixed-Position {
  position: fixed;
}

.kubota-modal {
  display: none;

  .kubota-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: .5;
  }

  .kubota-modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
  }

  .kubota-modal-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1a1a1a;
    color: #fff;
    padding: 8px 16px;
    cursor: pointer;
  }

  .kubota-modal-content {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 1.75rem auto;
    background: #fff;
  }
}
