@use "sass:math";
@import "~@styles/breakpoints";

.Tile {
  display: block;
  position: relative;
  padding-bottom: percentage(math.div(20, 152));
  cursor: pointer;
  
}
.ScaleHandler{
  overflow: hidden; 
  position: relative; 
  z-index: 1;
  
}
.Background {
 
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  img{
    transition: transform 0.4s, -webkit-transform 0.4s, -moz-transform 0.4s;
  }
  &:hover {   
    img{
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
      z-index: 100;
    }
    &::before {
      background-color: rgba(0, 0, 0, 0.4) !important;
    }
  }
}
.Background{
  &.Home{
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.6);
    } 
    &:hover {   
      &::before {
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }   
  } 
}

.BackgroundImage {
  width: 100%;
}

.Contents {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
}

.Text {
  color: #fff;
  max-height: 50%;
  position: relative;
  z-index: 100;
  &.Dark{
    color: #000;
  }
}

.Title {
  margin-bottom: 0.5em;
  line-height: 1.1em;
}

.Bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  
}

.Model {
  flex: 0 0 50%;
  img{
    transition: transform 0.4s, -webkit-transform 0.4s, -moz-transform 0.4s;
  }
  &:hover {
    img{
      -ms-transform: scale(1.15); /* IE 9 */
      -webkit-transform: scale(1.15); /* Safari 3-8 */
      transform: scale(1.15);
    }
   
  }
}

.ModelImage {
  width: 100%;
}

.Button {
  text-transform: none;
  padding: 1rem 1.5rem; 
  &.ViewAll{
    margin-top: 4rem;
    font-size: 1.4rem;
    padding: 0.6rem 1.5rem;
    @include media('<=md') {      
      margin-top: 5.2rem;  
      font-size: 2rem;    
    }
    @include media('>=lg') {      
      margin-top: 0;
      font-size: 1.4rem;
      padding: 0.6rem 0.7rem;
    }
    @include media('>xl') {
      margin-top: 4rem;
      font-size: 1.4rem;
      padding: 0.6rem 1.5rem;
    }
  }
  svg{
    margin-left: 1.4rem;
  }
}
