@import '~@styles/breakpoints';

.SearchWrapper {
  position: absolute;
  right: 0;
  height: 100%;
  display: block;
  font-size: 16px;
  color: #1a1a1a;
  user-select: none;

  @include media('<=md') {
    position: relative;
    height: 50px;
  }

  &.Expanded {
    left: 204px;
    z-index: 1;

    @include media('<=lg') {
      left: 60px;
    }
    @include media('<=md') {
      margin-top: 5px;
      left: 0px;
    }

    .SearchInput {
      display: block;
      width: 100%;
      padding: 0 50px;
      outline: none;
      color: inherit;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #1a1a1a;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #1a1a1a;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #1a1a1a;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #1a1a1a;
      }

      @include media('<=md') {
        padding-right: 10px;
      }
    }
  }
}

.SearchInput {
  display: none;
  border: none;
  height: 100%;
  width: 50px;
  background: #ffffff;
  color: #ffffff;
  float: right;
  z-index: 1;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: none;
}

.SearchToggle, .SearchInputPrepend {
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 22px;
    width: 22px;
    fill: #1a1a1a;
    color: #1a1a1a;
  }
}

.SearchToggle {
  right: 0;

  &:hover {
    svg {
      fill: var(--primary-color);
      color: var(--primary-color);
    }
  }

  @include media('<=md') {
    display: none;
  }
}

.SearchInputPrepend {
  left: 0;
}
