@import '~@styles/breakpoints';

.Hero {
  position: relative;
  width: 100%;
}

.SellPrice {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 2rem;

  @include media('<md') {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}

.FullscreenHero {
  composes: Hero;
  height: calc(100vh - var(--nav-height) - 9rem);

  .Container {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
}

.LargeDescription {
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}

.WithBackground {
  color: #fff;

  @include media('<md') {
    min-height: auto;
  }

  .Title {
    text-shadow: 0.04em 0.07em 0.07em rgb(0 0 0 / 40%);
  }

  .Label {
    --text-color: #000;
    --bg-color: #fff;
    box-shadow:  0 0 0 #fff;
  }
}

.Homepage {
  min-height: 38rem;

  @include media('<md') {
    min-height: 36rem;
    max-height: 36rem;
    display: flex;
    flex-direction: column-reverse;
  }

  &.componentTall {
    min-height: 60rem;
    @include media('<md') {
      min-height: 55rem;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.HasShadow {
  li[class^="Breadcrumbs_Item"] {
    color: #fff;

    @include media('<md') {
      color: #000;
    }
  }

  a {
    color: #fff;

    @include media('<md') {
      color: #000;
      width: fit-content;
    }

    &:not(.Button) {
      color: #fff !important;
    }
  }

  span {
    color: #f5f5f5;

    @include media('<md') {
      color: #000;
    }
  }
}

.Breadcrumbs {
  grid-area: breadcrumbs;

  @include media('<md') {
    width: 100%;
  }

  @include media('>=md') {
    margin: -4.5rem -4.5rem 0;
  }
}

.PostPage {
  min-height: 38rem;

  @include media('<md') {
    min-height: 36rem;
    max-height: 36rem;
  }

  &.componentTall {
    min-height: 60rem;

    @include media('<md') {
      min-height: 55rem;
    }
  }
}

.NoBackground {
  min-height: auto !important;

  .Container {
    padding-bottom: 0;
    position: relative;
    display: grid;
    grid-template-areas:
      'breadcrumbs'
      'content';
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 1fr;

    // @include media('>=md') {
    //   grid-template-areas:
    //     'breadcrumbs'
    //     'content';
    //   column-gap: 5%;
    //   grid-template-columns: repeat(1, 1fr);
    //   grid-template-rows: auto 1fr;
    // }
  }

  .Content {
    @include media('<md') {
      padding-bottom: 0 !important;
    }
  }

  @include media('<md') {
    * {
      min-height: auto !important;
    }
  }
}

.PostPageBreadcrumb {
  background: none;

  li {
    color: #fff;

    a,span {
      color: #fff;

      @include media('<md') {
        color:#fff;
      }
    }
    &::after {
      @include media('<md') {
        color:#fff;
      }
    }
  }
}

.SpecialBreadcrumb {
  background: none;

  li {
    color: #000;

    a,span {
      color: #000;

      @include media('<md') {
        color:#000;
      }
    }

    &::after {
      color:#000;
    }
  }
}

.Title {
  composes: H1 FontBold from '~@styles/typography.module.scss';
}

.subTitle {
  composes: H4 FontBold from '~@styles/typography.module.scss';
}

.postedBy {
  margin-bottom: 2rem;
  display: block;
}

.heroDate {
  composes: TextSmall2 from '~@styles/typography.module.scss';
  color: #999;
  margin-bottom: 2rem;
  display: block;
}

.Label {
  --x-offset: 6%;
  --text-color: #fff;
  --bg-color: #424242;
  composes: H3 FontBoldItalic from '~@styles/typography.module.scss';
  display: inline-block;
  margin-left: calc(var(--x-offset) * -1);
  padding-left: var(--x-offset);
  background-color: var(--bg-color);
  color: var(--text-color);
  box-shadow: var(--bg-color) 0.2rem 0.2rem 1rem;

  @include media('>=md') {
    --x-offset: 4.5rem;
    padding: 1.5rem var(--x-offset);
  }

  @include media('<md') {
    padding-right: 3rem;
  }
}

.Container {
  position: relative;
  display: grid;
  grid-template-areas:
    'breadcrumbs'
    'image'
    'content'
    'content'
    'badge';
  grid-template-rows: auto;

  &.NoBreadcrumbs {
    grid-template-areas:
      'image'
      'content'
      'content'
      'badge';
  }

  @include media('>=md') {
    grid-template-areas:
      'breadcrumbs image'
      'content image';
    column-gap: 5%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;

    &.NoBreadcrumbs {
      grid-template-areas: 'content image';
    }
  }

  @include media('>=md') {
    padding: 2.8rem 2rem;
  }

  @include media('>lg') {
    padding: 2.8rem 6rem;
  }
}

.ImageConatiner {
  @include media('<md'){
    min-height: 45vh;
  }
}

.Content {
  grid-area: content;

  @include media('<md') {
    padding: 5%;
  }
}

.ProductImage {
  grid-area: image;

  @include media('>=md') {
    display: flex;
    flex-direction: row;
  }
}

.ImageContainer {
  position: relative;
  padding-top: 0;
  flex: 1;
  margin: -2.8rem -4.5rem;

  @include media('<md') {
    padding-top: 0;
  }
}

.Image {
  object-fit: contain;

  @include media('<md') {
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.Legal {
  display: block;
  text-align: center;
  color: #c1c1c1;
  text-transform: uppercase;
  font-size: 0.7rem;

  @include media('>=lg') {
    font-size: 1rem;
  }
}

.HeroHomePage {
  .ProductImage {
    @include media('<md') {
      display: none;
    }
  }
}

// Product Group page Hero style
.ProductGroup {
  min-height: 38rem;

  @include media('<md') {
    min-height: 36rem;
    max-height: 36rem;
  }

  &.componentTall {
    min-height: 60rem;
    @include media('<md') {
      min-height: 55rem;
    }
  }

  .Container {
    @include media('<md') {
      min-height: 45vh;
      grid-template-areas:
        'breadcrumbs'
        'content'
        'badge';
      grid-template-columns: auto;
      grid-template-rows: auto 3fr 1fr;

      &.NoBreadcrumbs {
        grid-template-areas:
          'content'
          'badge';
      }
    }

    &.ImageConatiner {
      @include media('>=md') {
        grid-template-areas:
          'breadcrumbs breadcrumbs'
          'content image';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }

      @include media('<md') {
        min-height: 45vh;
        grid-template-areas:
          'breadcrumbs'
          'image'
          'content'
          'badge';
        grid-template-columns: auto;
        grid-template-rows: auto 1fr 1fr;
      }
    }

    &.VideoContainer {
      @include media('>=md') {
        grid-template-areas:
          'breadcrumbs'
          'content';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }

      @include media('<md') {
        grid-template-areas:
          'breadcrumbs'
          'content';
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
      }
    }
  }

  .Breadcrumbs {
    @include media('<md') {
      overflow: auto;
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.75);
      margin-bottom: 0;
    }

    * {
      color: #fff;

      @include media('<md') {
        color: #000;
      }
    }
  }

  .Label {
    background: #fff;
    color: #000;
    box-shadow: 0 0 0 #fff;

    @include media('>=md') {
      --x-offset: 4.5rem;
      padding: 0.5rem 1.3rem 0.5rem var(--x-offset);
    }
  }

  div[class^="Hero_LargeDescription"]{
    @include media('<md'){
      display: none;
    }
  }
}

// Product individual page Hero style
.ProductPage {
  min-height: 38rem;

  @include media('<lg') {
    min-height: 36rem;
    //max-height: 36rem;
    color: #000;
  }

  &.componentTall {
    min-height: 60rem;

    @include media('<md') {
      min-height: 55rem;
    }

    .ImageContainer {
      @include media('<md') {
        padding-top: 80%;
        margin: 0;
      }
    }
  }

  @include media('>md') {
    background: linear-gradient(0deg, #FFFFFF 12.24%, rgba(255, 255, 255, 0) 100%), #F2F2F2;
  }

  .Breadcrumbs {
    @include media('<md') {
      border: 1px solid #D8D8D8;
    }

    * {
      color: #000;
    }
  }

  div[class^="Hero_LargeDescription"] {
    @include media('<md') {
      display: none;
    }

    @include media('<md') {
      min-height: auto;
    }
  }

  .ImageContainer {
    @include media('<md') {
      padding-top: 40%;
      margin: 0;
    }
  }

  .Content {
    @include media('<md') {
      background: linear-gradient(180deg, #FFFFFF 12.24%, rgba(255, 255, 255, 0) 100%), #F2F2F2;
    }
  }
}

// category Group page Hero style
.CategoryGroup {
  min-height: 38rem;
  // max-height: 38rem;

  &.ComponentTall {
    min-height: 60rem;

    @include media('<md') {
      min-height: 55rem;
    }
  }

  .Breadcrumbs {
    background: none;

    * {
      color: #fff;
    }
  }

  @include media('<md') {
    min-height: 20rem;
    // max-height: 36rem;
  }
}

.DarkMode {
  min-height: 38rem;

  @include media('<md') {
    min-height: 36rem;
    max-height: 36rem;
  }

  &.componentTall {
    min-height: 60rem;

    @include media('<md') {
      min-height: 55rem;
    }
  }
}

.BadgeContainer {
  cursor: pointer;

  img {
    transition: transform 0.4s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  @include media('>md') {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
  }
}

.Badges {
  grid-area: badge;
  cursor: pointer;

  img {
    transition: transform 0.4s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  @include media('<md') {
    padding: 0 5% 5%;
  }
}

.componentTall {
  .Container {
    @include media('>=md') {
      padding: 4.5rem 6rem !important;
    }
  }

  .ImageContainer {
    position: relative;
    padding-top: 83%;
    flex: 1;

    @include media('<md') {
      padding-top: 40%;
      //padding-top: 0;
    }
  }
}

.ButtonsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;

  @include media('<md') {
    flex-direction: column;
  }

  Button {
    margin-right: 2rem;
    @include media('<md') {
      margin:  0 0 1rem 0;
    }
  }
}
