@import '~@styles/breakpoints';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  grid-template: auto auto;
}

.Dimmer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;

  &.SolidBg {
    opacity: 1;
    background: #313030;
  }
}

.Dialog {
  position: relative;
  width: 100%;
  padding: 3rem;
  background-color: #fff;
  z-index: 1;
  color: #000;

  &.DecisionModal {
    max-width: 23rem;
    min-width: 23vw;
    height: auto;
    margin: 5rem 0;

    .Title {
      margin-bottom: 5rem;
    }

    .BtnSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5rem;

      .Confirm {
        margin-left: 1rem;
      }
      * {
        flex: 1;
        height: 50px;
        padding: 0;
      }
    }
  }

  &.ContentModal {
    max-width: 70rem;
    min-width: 50vw;
    height: auto;
    overflow-y: auto;
    max-height: 80vh;

    @include media('<lg') {
      height: auto;
    }
    @include media('>=md') {
      margin: 4.5rem;
      margin-top: 11rem;
      max-width: auto;
      min-width: auto;
    }
    @include media('>lg') {
      margin: 6rem;
      margin-top: 11rem;
    }
    @include media('>=xl') {
      height: auto;
      min-width: 70vw;
    }
    @include media('<md') {
      margin-top: 10vh;
      height: 90vh;
      max-height: 90vh;
      max-width: auto;
      min-width: auto;
      overflow-x: hidden;
    }

    span {
      color: var(--table-text-color);
    }

    table {
      @include media('<md') {
        width: 100%;
        overflow-x: scroll;
      }

      thead {
        border: 1px solid var(--modal-table-border-color);
        tr {
          &:last-child {
            background: var(--modal-table-gray-bg);
          }
        }
        th {
          border-bottom: 1px solid var(--modal-table-border-color);
          border-right: 1px solid var(--modal-table-border-color);
          font-size: 1.8rem;
          font-weight: 750;
          padding: 0.3rem 0.5rem;

          &:last-child {
            border-right: 0;
          }
          span {
            font-size: 1.4rem !important;
            font-weight: 400;
            display: block;
          }
        }
      }
      tbody {
        border: 1px solid var(--modal-table-border-color);

        tr {
          border-bottom: 1px solid var(--modal-table-border-color);

          &:last-child {
            border-bottom: 0;
          }
          td {
            border-right: 1px solid var(--modal-table-border-color);
            text-align: center;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 0.3rem 0.5rem;

            &:last-child {
              border-right: 0;
            }
            span {
              display: block;
              font-size: 1.4rem !important;
              font-weight: 400;
            }
          }
        }
      }
    }

    .Title {
      font-size: 5rem;
      margin-bottom: 0;

      @include media('<xl') {
        margin-bottom: 1.5rem;
      }
      @include media('<md') {
        font-size: 2.5rem;
      }
    }
  }

  &.LightBoxModal {
    background: #313030;
    padding: 0;

    @include media('>=md') {
      max-width: 90%;
      overflow-y: auto;
      max-height: 90vh;
      // height: 56vh;
    }
    @include media('>=xl') {
      //max-width: 55%;
      // height: 60vh;
    }
    @include media('<md') {
      max-width: 100%;
      height: auto;
      padding: 2rem 0;
      background: #fff;
      overflow-y: auto;
      max-height: 100vh;
    }

    * {
      max-height: 100%;
      color: #fff;

      @include media('<md') {
        color: #000;
        max-height: 100%;
      }
    }

    .Title {
      font-size: 1.5rem;
      padding: 1rem 0 0 1rem;
    }

    iframe {
      height: 100%;
    }

    .CloseButton {
      color: #fff;
      top: 1rem;
      z-index: 1;

      @include media('<md') {
        color: #000;
        top: 1rem;
      }

      * {
        color: #fff;
        @include media('<md') {
          color: #000;
        }
      }

      &.NoTitle {
        position: absolute;
        right: 3rem;

        @include media('<lg') {
          right: 2rem;
        }
      }
    }
  }

  &.ApplicationsModal {
    width: auto;
  }
}

.Cancel {
  background: #d8d8d8;
  color: #000;
  margin-right: 1rem;
  color: var(--text-color);
  background: var(--modal-cancel-bg);
  font-weight: bold;
}

.CloseButton {
  width: 2.6rem;
  height: 2.6rem;
  padding: 0;
  border: none;
  cursor: pointer;

  @include media('<md') {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    color: var(--primary-color);
  }
}

.CloseIcon {
  width: 100%;
  height: 100%;
}

.Title {
  composes: H4 TextNormal from '~@styles/typography.module.scss';
  font-size: 2.5rem;
  padding: 0 3.5rem 0 0;
  margin-bottom: 0;
  //margin-bottom: 5rem;

  @include media('<md') {
    /*font-size: 1rem;*/
  }
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 2rem;
  align-items: center;

  @include media('<md') {
    padding: 1rem;
  }
}

.Body {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;

  .Arrow {
    display: flex;
    align-self: center;
    cursor: pointer;
    margin: 0 2rem;

    &.Right {
      margin-right: 0;
    }
    &.Left {
      margin-left: 0;
    }
  }

  .FImage {
    height: auto;
    max-height: 60vh;
    max-width: 85%;
  }

  &.LightBoxBody {
    margin-top: 1rem;
  }
}

.TextContent {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 2.5rem auto 2rem;

  @include media('<md') {
    width: 90%;
  }

  .MSubTitle {
    font-size: 2.9rem;
    margin-bottom: 1.2rem;
  }

  .CompTitle {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .CompSubtitle {
    font-size: 1.5rem;
  }
}
