@import '~@styles/breakpoints';

.Footer {
  position: relative;
  grid-row: 3 / -1;
  padding-bottom: 13.5rem;

  @include media('>=md') {
    padding-bottom: 33rem;
  }
}

.Background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  // background-image: url('/assets/bgs/kubota-footer-bg.jpg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 200% auto;

  @include media('>=md') {
    background-size: 100% auto;
  }
}

.Container {
  display: grid;
  grid-template-areas:
    'email email'
    'menu1 menu2'
    'menu3 language'
    'copyright copyright';
  grid-template-columns: repeat(1fr);

  @include media('>=md') {
    grid-template-areas:
      'email menu1 menu2 menu3'
      'copyright copyright copyright language';
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
  @include media('>lg') {
    grid-template-areas:
      'email menu1 menu2 menu3 .'
      'copyright copyright copyright language .';
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }
}

.Column {
  padding: 5%;

  @include media('>=xl') {
    padding: 3rem 3rem 0 3rem;
  }
  @include media('>=md') {
    padding: 3rem 2.5rem 0 2.5rem;
  }
}

.ColumnTitle {
  composes: FontBold from '~@styles/typography.module.scss';
  font-size: 1.4rem;
  margin-bottom: 2rem;

  @include media('>=lg') {
    font-size: 1.8rem;
  }
  @include media('<md'){
    padding-left: 1rem;
  }
}

.EmailColumnTitle {
  composes: FontBold from '~@styles/typography.module.scss';
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
}
.newLetter {
  flex-direction: row;
}
.news_letter_input {
  background: #f2f2f2;
  padding: 1rem;
}
.news_letter_input::-webkit-input-placeholder {
  font-size: 1.5rem;
  line-height: 3;
}
.news_submit {
  line-height: 0.6;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}
.button.disabled {
  background: #fff;
  opacity: 0.3;
  color: #000;
  cursor: initial;
  pointer-events: none;
  border-color: #000;
}
.EmailColumn {
  grid-area: email;

  @include media('<=md') {
    text-align: center;
  }
}
.Submit {
  padding: 0.7rem 1.5rem !important;
  font-size: 1.8rem !important;
}
.EmailInput {
  height: 40px;
  background: #F2F2F2;
  color: rgba(38, 38, 38, 0.5);
  border: 0;
  outline: 0;
  &:hover,
  :active,
  :focus{
    outline: 0;
    border: 0;
  }
}
.MenuColumn {
  grid-area: 'menu1,menu2,menu3';
  line-height: 1.6;

  @include media('>=md') {
    font-size: inherit;
    padding-left: 1rem;
    padding-right: 0;
  }
  @include media('>lg') {
    padding-left: 3rem;
    padding-right: 0rem;
  }
  @include media('>xl') {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.FooterMenu {
  display: flex;
  justify-content: space-between;

  @include media('<md') {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.MenuLeft,
.MenuRight {
  display: flex;
  list-style: none;
  flex-direction: column;
  margin-bottom: 0.5em;
  padding-left: 0;

  @include media('<md') {
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
  }
}

.MenuRight {
  grid-area: language;
  margin-left: 0;
  flex-direction: row;
  margin-top: 2rem;

  @include media('<md') {
    justify-content: flex-start;
    padding: 0 5%;
    flex-direction: column;
  }
   @include media('>=md') {
    margin-bottom: 0;
    padding-left: 1rem;
    margin-top: 0;
  }
  @include media('>lg') {
    margin-bottom: 0;
    padding:0 0 0 3rem;
    margin-top: 0;
  }
  @include media('>xl') {
    padding: 0 5rem;
    margin-top: 0;
    margin-bottom: 5rem;
  }

  .MenuItem{
    text-decoration: underline;

    @include media('>=md') {
     margin-bottom: 0;
     display: flex;
    }
  }
}

.MenuItem {
  margin: 0 1em;

  @include media('>=md') {
    margin: 0 1em 0.9em 0;
    line-height: 1.7rem;
  }
}

.Link {
  composes: Link TextSmall2 from '~@styles/typography.module.scss';
}

.LocaleLink {
  font-size: 1.4rem;

  @include media('>=lg') {
    font-size: 1.5rem;
  }
}

.MenuRight {
  .Link {
    border-bottom-color: var(--primary-color);
  }
}

.SocialColumn {
  grid-area: social;
}
.Fefl {
  margin: 0;
  padding: 0;
  list-style: none;
}
.SocialLinks {
  display: flex;
  padding: 0;
  margin: 1rem 0 2rem;
  list-style: none;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include media('<md') {
    justify-content: center;

    li {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

.SocialItem {
  margin-right: 1rem;

  @include media('<xl') {
    margin-right: 0.7rem;
  }
  @include media('<lg') {
    margin-right: 0.4rem;
  }
}

.SocialIcon {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media('>=lg') {
    width: 4rem;
    height: 4rem;
  }
  @include media('<md') {
    justify-content: center;
  }

  svg {
    align-items: center;
    justify-content: center;
    fill: var(--primary-color);
    color: var(--primary-color);
  }
}

.CopyrightNotice {
  composes: TextSmall3 from '~@styles/typography.module.scss';
  grid-area: copyright;
  margin-top: 0rem;
  text-align: left;
  font-size: 1.1rem;
  color: #000;
  font-weight: 600;
  margin-left: 3rem;

  @include media('<md') {
    margin-top: 0;
    margin-bottom: 10rem;
    text-align: center;
  }
}

.DarkMode {
  .EmailColumnTitle,
  .ColumnTitle,
  .Link,
  .LocaleLink {
    color: #c5c5c5;
  }
}
.SocialLinksTitle{
  margin-bottom: 0;
  @include media('<md') {
    text-align: left;
  }
}