@import '~@styles/breakpoints';

.Header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 1px 2px #ddd
  //box-shadow: 0px 2px 20px 0px #1b1b18;
}
