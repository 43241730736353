.Bar{
    background-color: rgba(220,68,5, 0.8);
    height: 0.5rem;
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 50;
}
.Container{
    pointer-events: none;
}