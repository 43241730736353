.Container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Slider {
  position: relative;
  min-width: 200px;
  width: 100%;
}

.SliderTrack,
.SliderRange,
.SliderLeftValue,
.SliderRightValue {
  position: absolute;
}

.SliderTrack,
.SliderRange {
  border-radius: 3px;
  height: 5px;
}

.SliderTrack {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.SliderRange {
  background-color: #DC4405;
  z-index: 2;
}

.SliderLeftValue,
.SliderRightValue {
  color: #707070;
  font-size: 12px;
  margin-top: 20px;
}

.SliderLeftValue {
  left: 6px;
}

.SliderRightValue {
  right: -4px;
}

/* Removing the default appearance */
.Thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  min-width: 200px;
  width: 100%;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  &::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
}

.ThumbLeft {
  z-index: 3;
}

.ThumbRight {
  z-index: 4;
}
