@import '~@styles/breakpoints';

.Wrapper {
  position: relative;

  &.DarkMode {
    background: var(--dark-mode-bg-color);
    color:var(--dark-mode-text-color) !important;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100rem;
    content: '';
    display: block;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 80%,
      rgba(193, 193, 193, 0.3)
    );
    z-index: -1;
    max-height: 100%;
  }
}

.Body {
  position: relative;
  display: flex;
  flex-direction: column;
}
