@import '~@styles/breakpoints';


.Tab {
  composes: FontBold H4 from '~@styles/typography.module.scss';
  display: flex;
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
  justify-content: space-between;
  
}

dl[class^="Specs_SpecAccordion"]{
  
  .Tab {
    border-bottom: 2px solid rgba(0,0,0,0.2);
    padding: 1.5rem 1rem;
  }
  .Content {    
    margin: 1.9rem 1rem; 
    
    @include media('<md'){
      padding: 1.2rem 0;
      font-size: 1.4rem;
      margin: 1.9rem 0rem;
    }
    @include media('>=lg') {
      padding: 1.5rem 0;      
    }
    & > dl {
      &:first-child{
        margin-top: 0;
      }

      &:last-child{
        margin-bottom: 0;
      }
      &:nth-child(2n){        
        background: #F5F5F5;        
      }     
      dl{                 
        padding: 1rem 1.7rem;
        &:first-child{
          padding-top: 0;
        }
      }
      
      
      &:last-child{
        margin: 0 1rem; 
      }     
    }
   
  }
}

dl[class^="ProductComparison_ComparePageAccordion"]{
 
  .Tab {
    border-bottom: 2px solid rgba(0,0,0,0.2);
    padding: 1.5rem 1rem;
  }
  .Content {    

    @include media('>=lg') {
      padding: 3rem 0;      
    }
    & > dl {
      
      &:nth-child(2n){        
        background: #F5F5F5;
        padding-top: 1.9rem;     
        dl{
          padding-bottom: 1.9rem;
        }
      }
      &:nth-child(2n+1){
        dl{
          
          &:last-child{
            padding-top: 1.9rem;
          }
          &:first-child{
            padding-top: 0;
          }
        }
      }
      dl{
        &:last-child{          
          padding-right:1.7rem;
          padding-left:1.7rem;

          @include media('<md'){
            // display: flex;
            // flex-direction: row;
            // flex-wrap: nowrap;
            // overflow-x: auto;
            // padding: 2rem 0 2rem 3rem;
            padding-right:0;
            padding-left:0;
          }
          dd{
            @include media('<md'){
              flex: 1 1 auto;
              min-width: 47%;
              max-width: inherit;
              margin-left: 0;
            }
          }
        }
        &:first-child{
         
          padding-right:0;
          padding-left:0;
        }
        
      }
      
      padding: 0 1rem;      
    }
   
    @include media('<md'){
      overflow-x: auto;
      
    }
  }
}
.TabArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  transform: rotate(-90deg);

  &.Expanded {
    transform: rotate(90deg);
  }

  svg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.Panel {
  overflow: 'visible';
  margin: 0;
}

.Content {
  padding: 1.2rem;
  font-size: 1.4rem;

  @include media('>=md') {
    padding: 3rem;
  }

  @include media('>=lg') {
    padding: 3rem 9rem;
    font-size: 1.6rem;
  }
}