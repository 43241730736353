.ComparePopup {
  position: fixed;
  bottom: 0;
  right: 0;
  height: fit-content;
  min-height: 100px;
  z-index: 1000;
  background: #fff;
  padding: 7px;
  width: 200px;
  box-shadow: rgb(193 193 193 / 30%) 0 0 30px;
  border: 3px solid rgba(255,255,255,0);
  transition: all .3s cubic-bezier(.165,.84,.44,1);

  .Accordion * {
    padding: 0;
  }

  h4 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2.4rem;
  }

  .Box {
    border-bottom: 2px solid #c1c1c1;
    position: relative;
    z-index: 0;

    img {
      height: 100px;
      width: auto;;
    }
    p {
      color: #7f7f7f;
    }
  }

  .Button{
    width: 100%;
    margin-top: 1rem;
    height: 3.5rem;

    &:disabled {
      opacity: 0.5;
      cursor: pointer;
      z-index: -1;
    }
  }

  .CloseButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: none;
    cursor: pointer;
    display: flex;
    background: #c4c4c4;
    justify-content: center;
    opacity: 1;
    z-index: auto;

    &:hover {
      color: var(--primary-color);
    }
  }

  .CloseIcon {
    width: 55%;
    align-self: center;
    font-weight: bold;
  }
}
