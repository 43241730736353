@import 'breakpoints';

// Typography

.FontRoman {
    font-family: 'nimbus-sans', sans-serif;
}

.FontNormal {
  font-family: 'nimbus-sans', sans-serif;
}

.FontItalic {
  font-family: 'nimbus-sans', sans-serif;
  font-style: italic;
}

.FontBold {
  font-family: 'nimbus-sans', sans-serif;
  font-weight: 600;
}

.FontBoldItalic {
  font-family: 'nimbus-sans', sans-serif;
  font-style: italic;
  font-weight: 600;
}

.FontCondensed {
  font-family: 'nimbus-sans', sans-serif;
  font-weight: 600;
} 

.FontCondensedItalic {
  font-family: 'nimbus-sans', sans-serif;
  font-style: italic;
}

.FontBoldCondensed {
  font-family: 'nimbus-sans', sans-serif;
  font-weight: 600;
}

.FontBoldCondensedItalic {
  font-family: 'nimbus-sans', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.FontHeavy {
  font-family: 'nimbus-sans', sans-serif;
  font-weight: 600;
}

/* .FontRoman {
    font-family: 'nimbus-sans', sans-serif;
}

.FontNormal {
  font-family: 'Helvetica Neue LT W01_71488914', sans-serif;
}

.FontItalic {
  font-family: 'nimbus-sans', sans-serif;
  font-style: italic;
}

.FontBold {
  font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
}

.FontBoldItalic {
  font-family: 'Helvetica Neue LT W05_86 Hv It', sans-serif;
}

 .FontCondensed {
  font-family: 'nimbus-sans', sans-serif;
  
  font-weight: 600;}

.FontCondensedItalic {
  font-family: 'Helvetica Neue LT W05_47LtCnOb', sans-serif;
}

.FontBoldCondensed {
  font-family: 'Helvetica Neue LT W01_97 BlkCn', sans-serif;
}

.FontBoldCondensedItalic {
  font-family: 'Helvetica Neue LT W0197 BlkCnObl', sans-serif;
}

.FontHeavy {
  font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
} */

.H1 {
  font-size: 3.8rem;
  line-height: 1.1;

  @include media('>=lg') {
    font-size: 7.2rem;
  }
}

.H2 {
  font-size: 3.8rem;

  @include media('>=lg') {
    font-size: 5rem;
  }
}

.H3 {
  font-size: 2.8rem;

  @include media('>=lg') {
    font-size: 2.9rem;
  }
}

.H4 {
  font-size: 1.8rem;

  @include media('>=lg') {
    font-size: 2.2rem;
  }
}

.TextLarge {
  font-size: 1.6rem;

  @include media('>=lg') {
    font-size: 1.8rem;
  }
}

.TextSmall {
  font-size: 1.4rem;
}

.TextSmall2 {
  font-size: 1.2rem;
}

.TextSmall3 {
  font-size: 1rem;
}

.TextInverted {
  color: var(--inverted-text-color);
}

.Link {

  &:hover,
  &:active {
    color: var(--primary-color);
  }
}