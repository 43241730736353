@import '~@styles/breakpoints';

.searchInput {
  #searchfield-container {
    .addsearch-searchfield {
      max-width: 100% !important;

      input[type=search].icon {
        background-image: none !important;
        padding: 0 5px 0 !important;
        border: none;
        -webkit-appearance: none;
        outline: none;
      }

      button {
        @include media('<=md') {
          padding: 11px 16px;
        }
      }
    }
  }

  #searchfield-container2 {
    .addsearch-searchfield {
      input[type=search].icon {
        outline: none;

        &:focus {
          background-color: #ffffff;
        }

        @include media('<=md') {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }
      }

      button {
        @include media('<=md') {
          font-size: 15px;
          padding: 11px 16px;
        }
      }
    }
  }

  #autocomplete-container {
    margin-top: 4px;

    .addsearch-autocomplete {
      max-height: calc(100vh - 110px);
      overflow: auto;
      box-shadow: 0 5px 20px rgba(0,0,0,.25);
    }

    .adds-title {
      margin-bottom: 0.8rem;
    }

    .adds-hit {
      padding: 0;
    }
  }
}

.searchInputNav {
  .adds-components {
    @include media('<=md') {
      padding: 0;
    }
  }
}