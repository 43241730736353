@import '~@styles/breakpoints';

.MenuLabel {
  composes: Link from '~@components/Nav/Nav.module.scss';

  @include media('<md') {
    position: relative;
    width: 100%;
    justify-content: space-between;

    .Icon {
      width: 1.5rem;
      height: 1.5rem;
      transform: rotate(-90deg);
    }
  }

  .ArrowDown {
    margin-left: 1rem;

    @include media('>md') {
      margin-left: 0.6rem;
    }
    @include media('>xl') {
      margin-left: 1rem;
    }

    &.RichArrowDown {
      transform: scale(2.0);
      -webkit-transform: scale(2.0);
    }
  }

  .OpenArrowDown {
    margin-left: 1rem;
    color: var(--primary-color);

    @include media('>md') {
      margin-left: 0.6rem;
    }
    @include media('>xl') {
      margin-left: 1rem;
    }

    &.RichOpenArrowDown {
      transform: scale(2.0);
      -webkit-transform: scale(2.0);
    }
  }
}

.MainMenuLabel {
  font-size: 1.6rem;
  font-weight: bold;
}

.FindDealerWrapper {
  position: relative;
  display: flex;

  @include media('<md') {
    flex-direction: column;
  }
}

.FindDealer {
  background: var(--primary-color);
  color: #fff !important;
  font-family: 'nimbus-sans', sans-serif;
  
  font-weight: 600;  
  padding: 0 1.8rem;
  user-select: none;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    border: 0;
  }

  svg {
    width: 12px;
    margin-right: 8px;
    fill: #fff;
  }

  @include media('<md') {
    padding: 1.6rem 2rem;
  }
}

.FindDealerDropdown {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 2;
  width: 312px;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  backdrop-filter: blur(20px);
  transition: all 0.3s;
  padding: 29px 29px 21px;

  @include media('<md') {
    position: relative;
    top: 0;
    width: 100%;
    border: none;
  }

  h3 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.025em;
    margin-bottom: 28px;
  }

  h4 {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    color: #3c3c3c;
    margin-bottom: 4px;
  }

  .ContentAddress {
    margin-bottom: 18px;
  }

  .ContentHours {
    margin-bottom: 28px;

    span {
      display: inline-block;
      min-width: 80px;
      margin-right: 8px;
    }
  }

  .ContentActions {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    margin-bottom: 24px;

    a {
      flex: 1;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.312885px;
      padding: 0.65rem 1.5rem;
    }
  }

  .ContentLink {
    display: flex;
    justify-content: center;

    a {
      font-family: 'nimbus-sans', sans-serif;
    font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #111111;

      &:hover, &:active {
        color: var(--primary-color);
        border: none;
      }

      svg {
        margin-left: 14px;
      }
    }
  }
}

.ActiveLabel {
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);

  @include media('<md') {
    box-shadow: 0 0.4rem 0.4rem rgb(193 193 193 / 30%);

    .Icon {
      transform: rotate(90deg);
    }
  }
}

.MenuDrawer {
  @include media('<md') {
    background-color: #f3f3f3;
  }
  @include media('>=md') {
    width: 90%;
    max-width: 1366px;
    overflow-y: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: 0.1rem solid var(--border-color);
    background-color: var(--bg-color);
    box-shadow: 1px 2px 6px #6c6b6b9e;
  }

  &.RichMenuDrawer {
    @include media('>=md') {
      width: 100%;
      z-index: 1;
    }
  }
}

.CloseButton {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  margin: 2rem;
}

.CloseIcon {
  width: 2.2rem;
  height: 2.2rem;
}

.SubMenuContainer {
  background: #fff;

  @include media('<md') {
    width: 100%;
    padding: 2% 5% 0;
  }

  @include media('>=md') {
    display: flex;
    position: relative;
    flex: 1;
    padding: 0;
    flex-direction: column;
  }
}

.Icon {
  composes: Icon from '~@components/Nav/Nav.module.scss';
}

.Promos {
  display: flex;
  width: 100%;

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @include media('<md') {
      grid-template-columns: repeat(1, 1fr);
      background: #fff;
    }

    li {
      position: relative;
      width: 100%;
      height: 126px;

      @include media('<md') {
        border-bottom: 0.1rem solid rgba(193, 193, 193, 0.8);
        margin: 0 5% 5%;
        width: 90%;
        overflow: hidden;
      }

      a {
        display: block;
        height: 100%;
        width: 100%;

        &::before {
          content: normal;
        }
        &:hover {
          .Content * {
            color:var(--primary-color);
          }
        }

        .BackgroundContainer {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          // height: fit-content;
          height: 100%;
          z-index: 0;

          .BoxShadow {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-box-shadow: inset 0px -16em 2em -7em rgb(0 0 0 / 50%);
            box-shadow: inset 0px -16em 2em -7em rgb(0 0 0 / 50%);
            bottom: 0;
            left: 0;
            @include  media('<md'){
              box-shadow: inset 0px -11em 4em -8em rgb(0 0 0 / 50%)
            }
          }
          img{
            height: auto;
            max-width: 100%;
            width: 100%;
            min-height: 100%;
          }
        }

        .Content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 2%;
          justify-content: flex-end;

          @include media('<md') {
            display: flex;
            flex-direction: column;
            padding: 2%;
            justify-content: flex-start;
            position: relative;
          }

          h2 {
            --nav-sub-menu-default-size: 1.6rem;

            font-weight: 750;
            color: #fff;
            font-size: var(--nav-sub-menu-default-size);
            margin-bottom: 0.3rem;
            align-items: center;
          }

          h3 {
            font-weight: normal;
            color: #fff;
            font-size: var(--nav-default-size);
            margin-bottom: 0;
            align-items: center;
          }
        }
      }
    }
  }

  &.RichPromos {
    ul {
      li {
        a {
          .Content {
            h2 {
              --nav-sub-menu-default-size: 1.8rem;

              font-size: var(--nav-sub-menu-default-size);
            }
          }
        }
      }
    }
  }
}

.PromoSubTitle {
  composes: FontNormal TextSmall2 from "~@styles/typography.module.scss";
}

.Promo {
  display: flex;
  width: 100%;

  ul {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    @include media('<md') {
      grid-template-columns: repeat(1, 1fr);
      background: #fff;
    }

    li {
      position: relative;
      width: 100%;
      height: 100%;

      @include media('<md') {
        border-bottom: 0.1rem solid rgba(193, 193, 193, 0.8);
        margin: 0 5% 5%;
        width: 90%;
      }
    }

    h2 {
      font-weight: normal;
      position: absolute;
      bottom: 2rem;
      z-index: 0;
      color: #fff;
      left: 2rem;

      @include media('<md') {
        color: #000;
        position: static;
      }
    }

    h3 {
      font-weight: normal;
      position: absolute;
      bottom: 0;
      z-index: 0;
      color: #fff;
      left: 2rem;

      @include media('<md') {
        color: #666;
        position: static;
      }
    }
  }
}


.Overlay {
  padding: 0.5rem;
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transition: all cubic-bezier(0.17, 0.67, 0.46, 0.92) 0.8s;
  clip-path: polygon(-110% 0, 0 0, -10% 100%, -110% 100%);
  color: #000;

  @include media('<md') {
    justify-content: flex-start;
    padding-left: 2rem;
  }
}

.Animated {
  position: relative;
  overflow: hidden;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  transition: box-shadow ease-out 0.2s;

  &:hover {
    color: #fff;
    background: var(--primary-color);

    &:before {
      transform: translateX(100%);
    }

    .Overlay {
      clip-path: polygon(110% 0, 200% 0, 190% 100%, 100% 100%);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: #000;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform cubic-bezier(0.17, 0.67, 0.46, 0.92) 0.8s;
    background: var(--primary-color);
    border-left: var(--primary-color) 4em solid;
    border-right: var(--primary-color) 2em solid;
    transform: translateX(-100%);
  }
}
