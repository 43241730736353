@import '~@styles/breakpoints';

.Button {
  --button-text-color: #fff;
  --button-bg: var(--primary-color);
  composes: FontCondensed from '~@styles/typography.module.scss';
  display: inline-block;
  color: var(--button-text-color);
  background: var(--button-bg);
  border: 0;
  //padding: 1rem 2rem;
  padding: 1em;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;

  @include media('>=lg') {
    font-size: 1.8rem;
  }
  @include media('<=md'){
    padding: 1rem 2rem;
  }
}

.Animated {
  position: relative;
  overflow: hidden;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  transition: box-shadow ease-out 0.2s;
  border-radius: 10px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0.2em 0.2em 0.5em;
    color: #fff;
    background: var(--primary-color);

    &:before {
      transform: translateX(100%);
    }

    .Overlay {
      clip-path: polygon(110% 0, 200% 0, 190% 100%, 100% 100%);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: #000;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform cubic-bezier(0.17, 0.67, 0.46, 0.92) 0.8s;
    background: var(--primary-color);
    border-left: var(--primary-color) 4em solid;
    border-right: var(--primary-color) 2em solid;
    transform: translateX(-100%);
  }
}

.Outlined {
  border: solid 0.2rem var(--primary-color);
  background: none;
  color: var(--text-color);

  &:hover {
    background-color: var(--primary-color);
    color: var(--inverted-text-color);
  }
}

.Overlay {
  padding: 0.5rem;
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transition: all cubic-bezier(0.17, 0.67, 0.46, 0.92) 0.8s;
  clip-path: polygon(-110% 0, 0 0, -10% 100%, -110% 100%);
  color: #000;
}
.BorderRadius {
  border-radius: 10px;
}

.Light {
  border: solid 0.2rem var(--inverted-text-color);
  background: var(--inverted-text-color);
  color: var(--inverted-bg-color);
  transition: box-shadow ease-out 0.2s;

  &:before {
    transform: translateX(100%);
  }
  &:hover {
    border: solid 0.2rem var(--primary-color);
    background-color: var(--primary-color);
    color: var(--inverted-text-color);
    box-shadow: rgba(0, 0, 0, 0.4) 0.2em 0.2em 0.5em;
  }
}
