@use "sass:math";
@import "~@styles/breakpoints";

.SubMenu {
  display: grid;

  @include media("<md") {
    font-size: 1.4rem;
  }

  @include media(">=md") {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }

  &.Expanded {
    flex: 1;
    @include media("<md") {
     display: flex;
     flex-direction: column-reverse;
    }
  }

  .Expanded + & {
    width: percentage(math.div(1, 3));
    flex: 0 auto;
    display: block;
    border-left: 0.1rem solid var(--border-color);
    padding-left: 3rem;
    margin-left: 3rem;
  }

  &.TwoSubMenu {
    grid-template-columns: repeat(1, 1fr);

    @include media(">=md") {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }

    > li {
      @include media(">=md") {
        padding: 1rem 2rem 2rem;

        &:last-child {
          background: var(--submenu-backgroud);
        }
      }

      > ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        width: 100%;

        @include media("<md") {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  &.NonSubMenuItems {
    padding: 3rem 6rem 5.1rem;
  }
}

.SubMenuItem {
  overflow: hidden;
  padding: 0.5em 0;
  composes: FontBold from "~@styles/typography.module.scss";
  font-size: 1.6rem;

  @include media("<md") {
    border-bottom: 0.1rem solid rgba(193, 193, 193, 0.8);
  }

  &::before {
    display: none;
  }
}

.SubMenuLink {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.SubMenuLinkImg {
  width: 50px;
}

.SubMenuLinkText {
  --nav-sub-menu-default-size: 1.6rem;

  font-weight: var(--nav-sub-menu-default-weight);
  font-size: var(--nav-sub-menu-default-size);
  letter-spacing: 0.1px;

  span {
    display: block;
    font-weight: var(--nav-sub-menu-default-weight);
    font-size: var(--nav-sub-menu-default-size);
    line-height: 1.2;
  }

  &.RichSubMenuLinkText {
    --nav-sub-menu-default-size: 1.8rem;

    font-weight: var(--nav-sub-menu-default-weight);
    font-size: var(--nav-sub-menu-default-size);
    letter-spacing: 0.1px;

    span {
      display: block;
      font-weight: var(--nav-sub-menu-subtitle-weight);
      font-size: var(--nav-sub-menu-subtitle-size);
      line-height: 1.2;
    }
  }
}

.SubMenuTitle {
  display: flex;
  font-weight: var(--nav-sub-menu-title-weight);
  font-size: var(--nav-sub-menu-title-size);
  letter-spacing: 0.402281px;
  align-items: flex-start;
  gap: 10px;

  span {
    font-weight: var(--nav-sub-menu-title-weight);
    font-size: var(--nav-sub-menu-title-size);
  }
}

.NestedSubMenu {
  margin-top: 1.5em;

  @include media(">md") {
    padding-right: 2rem;
  }
  @include media("<md") {
    padding-right: 0.5;
    margin-top: 1em;
  }

  &.RichNestedSubMenu {
    border-top: 1px solid #bababa;
    margin-top: 1em;
    padding-top: 1.5em;
  }
}

.NestedSubMenuItem {
  composes: FontNormal TextSmall2 from "~@styles/typography.module.scss";
  margin-bottom: 1em;

  @include media("<md") {
    margin-bottom: 1rem;
  }
}
